import { template as template_a7199cd807074fa3b0dc3fbe935c592f } from "@ember/template-compiler";
const FKControlMenuContainer = template_a7199cd807074fa3b0dc3fbe935c592f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
